import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import TabBusinessTypes from '../../sections/settings/configurations/general/TabBusinessTypes';
import organizationProp from '../../commonComponents/propTypes/organizationProp';
import Notifications from '../../services/notifications';
import BusinessTypesProp from '../../sections/settings/configurations/propTypes/businessTypesProp';

const TabBusinessTypesWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { t, organization, error, hideNotificationError, businessTypes } =
    props;
  const { businessTypes: businessInOrganization = [] } = organization || {};
  const [organizationBusinessTypes, setOrgBusinessTypes] = useState(
    businessInOrganization,
  );

  // since we are injecting in ejs, the react component don't have the "normal behavior"
  // ref to make sure we only show error first time it shows
  const isMountedRef = useRef(false);

  const { details: businessErrors } = error || {};
  // let organizationBusinessTypes = businessInOrganization;
  if (
    !hideNotificationError &&
    businessErrors?.length > 0 &&
    !isMountedRef?.current
  ) {
    Notifications.showNotificationError(
      t('error'),
      t('ERROR_REMOVING_BUSINESS_TYPES', JSON.stringify(businessErrors)),
    );
    isMountedRef.current = true;
  }

  useEffect(() => propagateChanges(businessTypes), [businessTypes]);

  const handleChanges = useCallback(
    field => value => {
      setOrgBusinessTypes(old => {
        let newValue = null;
        switch (field) {
          case 'businessTypes':
            newValue = old.includes(value)
              ? [...old.filter(c => c !== value)]
              : [...old.concat(value)];
            break;
          default:
            break;
        }
        return newValue;
      });
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    getValue: () => businessTypes,
    setValue: newBusinessInfos => setOrgBusinessTypes(newBusinessInfos),
  }));

  return (
    <>
      <TabBusinessTypes
        {...childProps}
        businessTypes={businessTypes}
        organizationBusinessTypes={organizationBusinessTypes}
        onChange={handleChanges}
      />
    </>
  );
});

TabBusinessTypesWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
  error: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
  }),
  hideNotificationError: PropTypes.bool,
  businessTypes: PropTypes.arrayOf(PropTypes.shape(BusinessTypesProp.propType))
    .isRequired,
};

TabBusinessTypesWebHandler.defaultProps = {
  hideNotificationError: false,
};

TabBusinessTypesWebHandler.defaultProps = {
  error: null,
};

window.TabBusinessTypes = class TabBusinessTypesRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabBusinessTypes container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabBusinessTypesWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
