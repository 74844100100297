import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../commonComponents/translate';
import SelectFilter from '../../../../commonComponents/lists/filters/SelectFilter';
import WarningCard from '../../../../commonComponents/cards/WarningCard';
import BusinessTypesProp, {
  BUSINESS_TYPES,
} from '../propTypes/businessTypesProp';

function TabBusinessTypes(props) {
  const { t, businessTypes, organizationBusinessTypes, onChange } = props;

  return (
    <div id="tabAvailableBusinessTypes">
      <input
        type="hidden"
        id="businessTypes"
        name="businessTypes"
        value={JSON.stringify(organizationBusinessTypes)}
      />
      <section>
        <h4>{t('settings.configurations.businessTypes')}</h4>
        <SelectFilter
          id="businessTypesSelector"
          placeholder={t('settings.configurations.businessTypes')}
          label={t('settings.configurations.businessTypes.available')}
          hint={t('settings.configurations.businessTypes.available')}
          onChange={onChange('businessTypes')}
          initialValue={organizationBusinessTypes.filter(
            b => b !== BUSINESS_TYPES.INDIVIDUAL,
          )}
          multiple
          items={businessTypes.map(c => ({
            id: c.code,
            text: c.emoji ? `${c.emoji} ${c.name}` : c.name,
          }))}
        />
        <WarningCard wrapperClass="warning-countries">
          <span>
            {t('settings.configurations.businessTypes.available.warning')}
          </span>
        </WarningCard>
      </section>
    </div>
  );
}

TabBusinessTypes.propTypes = {
  t: PropTypes.func.isRequired,
  businessTypes: PropTypes.arrayOf(PropTypes.shape(BusinessTypesProp.propType))
    .isRequired,
  organizationBusinessTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
TabBusinessTypes.defaultProps = {};

export default translate(TabBusinessTypes);
