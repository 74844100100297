import PropTypes from 'prop-types';

export const BUSINESS_TYPES = {
  INDIVIDUAL: 'individual',
};

const propType = {
  code: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  countryCode: PropTypes.string,
  emoji: PropTypes.string,
};

export default { propType };
